<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="showModel"
    :modal-width="70"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Create Reminder for {{ label }}</span>
    </template>
    <template v-slot>
      <div class="flex flex-wrap md:px-8 pt-4">
        <div class="mb-4 flex w-full flex-wrap px-3">
          <div class="md:flex xl:w-2/4 w-full">
            <p v-i18n="notifications" class="text-xl md:mt-6 mt-4 md:pr-7">REMINDER</p>
            <div class="sm:w-79 w-full ml-auto mt-4 md:pr-3 -mb-2">
              <UiSingleSelect
                v-model="alertObj.role"
                :select-object-without-reduce-key="true"
                :hide-title="true"
                rules="required"
                :label="'name'"
                :options="options"
                class="text-text-color font-roboto text-sm font-normal flex-1"
              />
            </div>
          </div>
          <div v-if="alertObj.role && alertObj.role.concerned" class="md:flex xl:w-2/4 w-full">
            <p class="text-xl md:mt-6 mt-4 md:pr-7">to:</p>
            <div class="sm:w-79 w-full mt-4 -mb-2 ml-auto md:pr-3">
              <UiSingleSelect
                v-model="alertObj.concerned"
                title="SELECT_USER"
                :hide-title="true"
                :select-object-without-reduce-key="true"
                rules="required"
                :label="'name'"
                :options="concerned"
                class="text-text-color font-roboto text-sm font-normal flex-1"
              />
            </div>
          </div>
          <div
            v-if="
              (alertObj.role && alertObj.role.except) ||
              (alertObj.concerned && alertObj.concerned.except)
            "
            class="md:flex xl:w-2/4 w-full"
          >
            <p class="text-xl md:mt-6 mt-4 md:pr-7">Except:</p>
            <div class="sm:w-79 w-full mt-4 -mb-2 ml-auto md:pr-3">
              <UiMultiSelect
                :key="`Excluded_user_Edit_Fix${forceRender}`"
                v-model="alertObj.except"
                :hide-title="true"
                title="SELECT_USERS"
                :already-selected="alreadyExcluded"
                rules="required"
                label="full_name"
                :options="usersList"
                class="text-text-color font-roboto text-sm font-normal flex-1"
                :class="$style.widthItems"
              />
            </div>
          </div>
          <div v-else class="md:flex xl:w-2/4 w-full">
            <p class="text-xl md:mt-6 mt-4 md:pr-7">User:</p>
            <div class="sm:w-79 w-full ml-auto mt-4 -mb-2 md:pr-3">
              <UiSingleSelect
                v-model="alertObj.user"
                title="Select User"
                reduce="id"
                :hide-title="true"
                rules="required"
                label="full_name"
                :options="usersList"
                class="text-text-color font-roboto text-sm font-normal flex-1"
              />
            </div>
          </div>

          <div class="md:flex xl:w-2/4 w-full">
            <p class="text-xl md:mt-6 mt-4 md:pr-7">Days:</p>
            <div class="sm:w-79 w-full ml-auto mt-4 -mb-2 md:pr-3">
              <UiInput
                v-model="alertObj.days"
                :hide-title="true"
                type="text"
                :rules="`required`"
                name="Days"
                title="Days"
                label="Days"
                placeholder="2 Days"
                class="flex-1"
                is-requried="true"
              />
            </div>
          </div>

          <div class="md:flex xl:w-2/4 w-full">
            <p class="text-xl md:mt-6 mt-4 md:pr-7">Before/After:</p>
            <div class="sm:w-79 w-full ml-auto mt-4 -mb-2 md:pr-3">
              <UiSingleSelect
                v-model="alertObj.days_before"
                title="Select User"
                :hide-title="true"
                :select-object-without-reduce-key="true"
                rules="required"
                :label="'name'"
                :options="featureAndActions.after_before_list || afterBeforeList"
                class="text-text-color font-roboto text-sm font-normal flex-1"
              />
            </div>
          </div>

          <div class="md:flex xl:w-2/4 w-full">
            <p class="text-xl md:mt-6 mt-4 md:pr-7">Due date at:</p>
            <div class="sm:w-79 w-full ml-auto mt-4 mb-4 md:pr-3">
              <div
                class="border rounded-lg flex flex-wrap justify-between gap-5 border-primary-grey"
              >
                <UiTimePicker
                  v-model="alertObj.due_date"
                  :hide-title="true"
                  class="flex-1"
                  :is-date-time="true"
                  input-color="bg-white"
                />
              </div>
            </div>
          </div>
        </div>
        <p class="text-2xl px-3 mb-4">Send Reminders through external mediums</p>
        <div class="mb-2 flex w-full flex-wrap px-3">
          <div class="w-full md:w-2/4 xl:w-1/2 md:pr-3">
            <p class="text-xl mt-2">1st Priority</p>
            <div class="w-full mt-4 -mb-2">
              <UiSingleSelect
                :key="`priority1${forceRenderPriority}`"
                v-model="prioirities.priority1"
                title="MEDIUM"
                :hide-title="true"
                :label="'name'"
                :rules="isRequired ? 'required' : ''"
                :options="mediumList"
                class="text-text-color font-roboto text-sm font-normal flex-1"
                @receiveList="itemSelected"
              />
            </div>
          </div>

          <div v-if="prioirities && prioirities.priority1" class="w-full md:w-2/4 xl:w-1/2 xl:pr-3">
            <p class="text-xl mt-2">2nd Priority</p>
            <div class="w-full mt-4 -mb-2">
              <UiSingleSelect
                :key="`priority2${forceRenderPriority}`"
                v-model="prioirities.priority2"
                :hide-title="true"
                :label="'name'"
                :options="mediumList"
                class="text-text-color font-roboto text-sm font-normal flex-1"
                @receiveList="itemSelected"
              />
            </div>
          </div>
        </div>
        <div class="mb-5 ml-2 flex flex-wrap">
          <span class="md:w-auto w-full pt-3 mt-1">Send to all priorities selected</span>
          <div class="px-4">
            <ToggleButton
              v-model="toggleValue"
              class="mt-5"
              checkbox-color-class="bg-dark-orange"
              :is-status-hide="true"
              :custom-style="{ backgroundColor: 'var(--primary-purple-600)' }"
            />
          </div>
          <span class="md:w-auto w-full pt-3 mt-1">Send to first priority found</span>
        </div>
        <div class="flex flex-wrap w-full mb-10 ml-2">
          <span class="md:w-auto w-full pt-3 mt-1">Send In App notification</span>
          <div class="px-4">
            <ToggleButton
              v-model="toggleInAppNotificationValue"
              class="mt-5"
              :is-status-hide="true"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-i18n="notifications"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        @click="handleClick"
      >
        Cancel
      </button>
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click="handleSubmit(createNewAlert(invalid))"
      >
        <span v-if="modalButtonLoading">
          <Loader />
        </span>
        <span v-else v-i18n="notifications">Save</span>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import NOTIFICATION_PATHS from '@src/constants/notification-constants.js'
import UiSingleSelect from '@components/UiElements/UiSingleSelect.vue'
import UiTimePicker from '@components/UiElements/UiTimePicker.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UiInput from '@components/UiElements/UiInputBox.vue'
import * as GENERAL_CONSTANTS from '@src/constants/general-constants'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import { mapActions, mapState } from 'vuex'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
export default {
  name: 'CreateEditLateFeeAlert',
  components: {
    UiModalContainer,
    Loader,
    // ValidationObserver,
    UiSingleSelect,
    UiInput,
    UiTimePicker,
    ToggleButton,
    UiMultiSelect,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    options: { type: Array, default: () => GENERAL_CONSTANTS.ROLES_LIST },
    concerned: { type: Array, default: () => null },
    edit: { type: Object, default: () => null },
    featureAndActions: { type: Object, default: () => ({}) },
  },
  emits: ['update', 'handleClick'],
  data() {
    return {
      notifications: 'notifications',
      dashboard: 'dashboard',
      modalButtonLoading: false,
      forceRender: 0,
      GENERAL_CONSTANTS,
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.INSTANT_NOTIFICATION,
      },
      prioirities: {},
      usersList: [],
      afterBeforeList: [
        { id: '0', name: 'Before' },
        { id: '1', name: 'After' },
      ],
      alreadyExcluded: [],
      mediumList: GENERAL_CONSTANTS.MEDIUM_LIST,
      defaultMediumList: GENERAL_CONSTANTS.MEDIUM_LIST,
      forceRenderPriority: 0,
      alertObj: { role: '', concerned: '', days: '' },
      toggleValue: false,
      toggleInAppNotificationValue: false,
    }
  },

  computed: {
    isRequired() {
      let returnVal = true
      if (this.toggleInAppNotificationValue) {
        returnVal = false
      }
      return returnVal
    },
    ...mapState({
      campusId: (state) => state.layout.currentCampusScope.id,
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
    }),
  },
  watch: {
    'alertObj.role': {
      handler(newVal) {
        if (newVal.concerned) {
          this.alertObj.user = null
        } else {
          this.alertObj.concerned = ''
        }
        if (!newVal.except) {
          this.alertObj.except = []
        }

        switch (newVal.getUser) {
          case 'teacher': {
            this._getTeacherData()
            break
          }
          case 'staff': {
            this._getStaffData()
            break
          }
          case 'student': {
            this._getStudentsData()
            break
          }
          case 'guardian': {
            this._getGaurdianData()
            break
          }
        }
      },
      deep: true,
    },
    'alertObj.concerned': {
      handler(newVal) {
        if (this.alertObj.role.concerned) {
          this.alertObj.role.value = newVal.value
          this.alertObj.role.receiverRoleId = newVal.receiverRoleId
        }
        if (!newVal.except) {
          this.alertObj.except = []
        }
      },
      deep: true,
    },
    edit: {
      handler(val) {
        if (val) {
          if (val.isConcerned) {
            this.alertObj.role = this.options.filter((opt) => {
              return opt.name === val.isConcernedVal
            })[0]
            this.alertObj.concerned = this.concerned.filter((concern) => {
              return concern.value === val.send_to
            })[0]
          } else {
            this.alertObj.role = this.options.filter((opt) => {
              return opt.value === val.send_to
            })[0]
            this.alertObj.user = val.receiver.id
          }
          /* NOTIFY_AT */
          this.alertObj.due_date = val.notify_at
          this.alertObj.days = val.before_after_quantity

          /* BEFORE AFTER */
          this.alertObj.days_before = val.is_before
            ? this.afterBeforeList[0]
            : this.afterBeforeList[1]
          // is_before

          /* Setting Prioriteies */
          val.mediums.forEach((medium, ind) => {
            this.prioirities['priority' + (ind + 1)] = this.mediumList.filter(
              (single_medium) => single_medium.name == medium,
            )[0]
          })
          this.itemSelected()
          /* IN APPNOTIFICATION ENABLED */
          this.toggleInAppNotificationValue = val.in_app_notification_enabled
          /* All Priorities */
          this.toggleValue = val.all_priorities ? val.all_priorities : false
          if (val.excluded_receivers && val.excluded_receivers.length) {
            this.alreadyExcluded = val.excluded_receivers
            this.forceRender++
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    itemSelected(id) {
      /* IN PROCESS */
      let selectedMediumsList = []
      if (this.prioirities?.priority1) selectedMediumsList.push(this.prioirities?.priority1)
      if (this.prioirities?.priority2) selectedMediumsList.push(this.prioirities?.priority2)
      this.mediumList = this.defaultMediumList.filter((val) => {
        const isNotPresent = !selectedMediumsList.some((priority) => priority.id === val.id)
        return isNotPresent
      })
      this.alertObj.mediums = selectedMediumsList
    },

    handleClick() {
      this.$emit('handleClick')
    },

    async createNewAlert(invalid) {
      if (invalid || this.modalButtonLoading) return
      const data = {
        ...this.featureAndActions,
        notify_at: this.alertObj.due_date,
        before_after_unit: 'days',
        before_after_quantity: this.alertObj.days,
        is_before: this.alertObj.days_before.name != 'After',
        send_to: this.alertObj.role.value,
        excluded_receivers: this.alertObj.except,
        first_priority_medium: this.prioirities?.priority1?.name,
        is_scheduled: true,
        second_priority_medium: this.prioirities?.priority2?.name,
        in_app_notification_enabled: this.toggleInAppNotificationValue,
        receiver_role_id: this.alertObj.role.receiverRoleId,
        all_priorities: this.toggleValue,
        receiver_id: this.alertObj.user,
      }
      let res, err
      if (this.edit) {
        data.id = this.edit.id
        this.dataPayload.payload = data
        ;[res, err] = await this.updateNotificationTrigger(this.dataPayload)
      } else {
        this.dataPayload.payload = data
        ;[res, err] = await this.createNotificationTrigger(this.dataPayload)
      }
      if (res) {
        this.$emit('update', this.featureAndActions)
        this.$emit('handleClick')
      }
    },

    async _getTeacherData() {
      const data = {
        campus_id: this.campusId,
        role_id: 'section_teacher',
        section_id: this.currentSectionScope,
      }
      const res = await this.loadClassStudents(data)
      this.usersList = res.records.map((teacher) => {
        teacher.full_name = `${teacher.first_name} ${teacher.last_name}`
        teacher.receiver_user_id = teacher.id
        return teacher
      })
      this.forceRender++
    },

    async _getStudentsData() {
      const data = {
        campus_id: this.campusId,
        role_id: 'section_student',
        section_id: this.currentSectionScope,
      }
      const res = await this.loadClassStudents(data)
      this.usersList = res.records.map((std) => {
        std.full_name = `${std.first_name} ${std.last_name}`
        std.receiver_user_id = std.id
        return std
      })
      this.forceRender++
    },

    async _getStaffData() {
      const res = await this.getStaffWithFilters()
      this.usersList = res.data.records.map((user) => {
        user.receiver_user_id = user.id
        return user
      })
      this.forceRender++
    },

    async _getGaurdianData() {
      const data = {
        campus_id: this.campusId,
        role_id: 'guardian',
      }
      const res = await this.loadClassStudents(data)
      this.usersList = res.records.map((guardian) => {
        guardian.full_name = `${guardian.first_name} ${guardian.last_name}`
        guardian.receiver_user_id = guardian.id
        return guardian
      })
      this.forceRender++
    },

    ...mapActions('staff', ['getStaffWithFilters']),
    ...mapActions('student', ['loadClassStudents']),
    ...mapActions('notifications', ['createNotificationTrigger', 'updateNotificationTrigger']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
